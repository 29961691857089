<template>
  <div class="main">
    <div class="header">
      <div class="header-d1">登录知否</div>
      <div class="header-d2">面向研究生的学术交流网站</div>
    </div>
    <van-tabs style="width: 200px;padding-top: 30px;text-align: center;margin: auto" v-model="active">
      <van-tab title="账号登录"/>
      <van-tab title="账号注册"/>
    </van-tabs>
    <div class="form" v-if="active === 0">
      <input placeholder="请输入手机" v-model="signInForm.account" class="input"/>
      <input placeholder="请输入密码" type="password" v-model="signInForm.pwd" class="input"/>
      <van-button @click="submitSignIn" type="info" class="btn">登 录</van-button>
    </div>
    <div class="form" v-if="active === 1">
      <input placeholder="请输入账号" v-model="signUpForm.account" class="input"/>
      <input placeholder="请输入密码" v-model="signUpForm.pwd" type="password" class="input"/>
      <input placeholder="请输入昵称" v-model="signUpForm.nick_name" class="input"/>
      <van-radio-group v-model="signUpForm.sex" direction="horizontal">
        <van-radio name="1">男</van-radio>
        <van-radio name="2">女</van-radio>
      </van-radio-group>
      <van-button @click="submitSignUp" type="info" class="btn">注 册</van-button>
    </div>
  </div>
</template>

<script>
import {checkSignInForm, checkSignUpForm} from "@/utils/check";
import {Login, SaveUser,register} from "@/api/user";
import axios from "axios";
import Cookies from 'js-cookie'
import {BASE_RUL} from "@/utils/request";

export default {
  data() {
    return {
      active: 0,
      fileList: [],
      signInForm: {
        account: '',
        pwd: '',
      },
      signUpForm: {
        nick_name: '',
        account: '',
        pwd: '',
        sex: '1',
      },
    };
  },

  methods: {

    onRead() {
      let formData = new FormData()
      let file = this.fileList[0].file
      formData.append("file", file, file.name)
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
      axios.post(BASE_RUL + "/file/image", formData, config).then((res) => {
        if (res.status === 200) this.signUpForm.avatar = res.data
      })
    },

    submitSignIn() {
      if (checkSignInForm(this.signInForm)) {
        Login(this.signInForm).then((res) => {
          if (res.code==0) {
            Cookies.set('accessToken', res.result.token, {expires: 365})
            this.$store.dispatch('updateUserInfo')
            this.$toast.success("登录成功")
            this.$router.push("/")
          }
        })
      }
    },

    submitSignUp() {
      if (checkSignUpForm(this.signUpForm)) {
        register(this.signUpForm).then((res) => {
          if (res.code==0) {
            this.$toast.success("注册成功")
            Cookies.set('accessToken', res.result.token, {expires: 365})
            this.$store.dispatch('updateUserInfo')
            setTimeout(() => {
              this.signUpForm = {}
              this.fileList = []
              this.active = 0
            }, 700)
          }
        })
      }
    },
  },

};
</script>

<style scoped>
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}

>>> .van-tab__text {
  font-size: 16px;
  letter-spacing: 1px;
}

.main {
  width: 100%;
  height: 100%;
}

.header {
  height: 110px;
  background: #0066ff;
  text-align: center;
  letter-spacing: 1px;
}

.header-d1 {
  color: #ffffff;
  font-size: 22px;
  padding-top: 25px;
}

.header-d2 {
  color: #f5f6f7;
  font-size: 13px;
  padding-top: 10px;
}

.form {
  width: 90%;
  height: 300px;
  margin: 20px auto;
}

.input {
  width: 94%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  color: rgba(0, 0, 0, .4);
  letter-spacing: 1px;
  margin: 10px 0;
  padding: 10px;
  font-size: 13px;
}

.btn {
  width: 100%;
  margin: 30px 0;
}

>>> .van-tabs__line {
  background-color: #0066ff;
  width: 30px;
}
</style>
